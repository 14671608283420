import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      requiredRule: [v => !!v || "Field is required"],
      isSaving: false,
      valid: false,
      categoriesSearchInput: null,
      citiesSearchInput: null,
      recommendedCategoryBulkInsert: {
        startDate: null,
        endDate: null,
        cityIds: null,
        categoryIds: null,
        allCities: false
      },
      categories: [],
      cities: [],
      search: "",
      citiesIsLoading: false,
      categoriesIsLoading: false,
      startDateMenu: false,
      endDateMenu: false
    };
  },
  created() {
    let title = this.$i18n.t('pages.recommendedCategories.insertCategory');
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    this.searchCities();
    this.searchCategories();
  },
  methods: {
    previousState() {
      this.$router.go(-1);
    },
    removeCategoryFromList(item) {
      const index = this.recommendedCategoryBulkInsert.categoryIds.indexOf(
        item.id
      );
      if (index >= 0)
        this.recommendedCategoryBulkInsert.categoryIds.splice(index, 1);
    },
    removeCityFromList(item) {
      const index = this.recommendedCategoryBulkInsert.cityIds.indexOf(item.id);
      if (index >= 0)
        this.recommendedCategoryBulkInsert.cityIds.splice(index, 1);
    },
    searchCities() {
      // Items have already been loaded
      if (this.cities.length > 0) return;

      // Items have already been requested
      if (this.citiesIsLoading) return;

      this.citiesIsLoading = true;
      ApiService.query("api/cities")
        .then(res => {
          this.cities = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.citiesIsLoading = false));
    },
    searchCategories() {
      // Items have already been loaded
      if (this.categories.length > 0) return;

      // Items have already been requested
      if (this.categoriesIsLoading) return;

      this.categoriesIsLoading = true;
      ApiService.query("api/shop-categories")
        .then(res => {
          this.categories = res.data;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.categoriesIsLoading = false));
    },
    validateAndSave() {
      if (this.$refs.form.validate()) {
        this.saveRecommendedCategoryBulk(this.recommendedCategoryBulkInsert);
      }
    },
    saveRecommendedCategoryBulk(recommendedCategoryBulkInsert) {
      this.loading = true;
      ApiService.post(
        `api/recommended-categories/bulk-insert`,
        recommendedCategoryBulkInsert
      )
        .then(response => {
          this.$log.debug("Recommended Category Bulk insert: " + response);
          this.$emit("recommendedCategorySaved", "recommendedCategorySaved");
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.previousState();
        });
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    ...mapGetters(["currentUser"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
